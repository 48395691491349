import Cookies from 'js-cookie';
import {
  addClasses,
  loadCSS,
  removeClasses,
  onLocationChangeListener,
  onResizeListener,
  toggleClass,
} from './DOMHelpers';
import {
  body,
  widgetHolder,
  createBubbleHolder,
  createBubbleIcon,
  chatBubble,
  bubbleHolder,
  createNotificationBubble,
  onClickChatBubble,
  onBubbleClick,
  setBubbleText,
  addUnreadClass,
  removeUnreadClass,
} from './bubbleHelpers';
import { isWidgetColorLighter } from 'shared/helpers/colorHelper';
import { dispatchWindowEvent } from 'shared/helpers/CustomEventHelper';
import {
  CHATWOOT_ERROR,
  CHATWOOT_ON_MESSAGE,
  CHATWOOT_ON_CONVERSATION_OPEN,
  CHATWOOT_ON_CONVERSATION_RESOLVED,
  CHATWOOT_READY,
  CHATWOOT_ON_LINK_CLICK,
  CHATWOOT_TOGGLE,
} from '../widget/constants/sdkEvents';
import { SET_USER_ERROR } from '../widget/constants/errorTypes';
import { getUserCookieName, setUserFromUrlIfAvailable } from './cookieHelpers';
import {
  getAlertAudio,
  initOnEvents,
} from 'shared/helpers/AudioNotificationHelper';
import { isFlatWidgetStyle } from './settingsHelper';
import { popoutChatWindow } from '../widget/helpers/popoutHelper';
import addHours from 'date-fns/addHours';

export const getCookieName = () =>
  window.$hoory.isSecondWidget ? 'cw_conversation2' : 'cw_conversation';

const updateAuthCookie = cookieContent =>
  Cookies.set(getCookieName(), cookieContent, {
    expires: 365,
    sameSite: 'Lax',
  });

const updateCampaignReadStatus = () => {
  const expireBy = addHours(new Date(), 1);
  Cookies.set('cw_snooze_campaigns_till', Number(expireBy), {
    expires: expireBy,
    sameSite: 'Lax',
  });
};

export const IFrameHelper = {
  getUrl({ baseUrl, websiteToken, isSecondWidget }) {
    return `${baseUrl}/widget?website_token=${websiteToken}&isw=${
      isSecondWidget ? 'true' : 'false'
    }`;
  },
  createFrame: ({ baseUrl, websiteToken, isSecondWidget }) => {
    if (IFrameHelper.getAppFrame()) {
      return;
    }

    loadCSS();
    const iframe = document.createElement('iframe');
    const cwCookie = Cookies.get(getCookieName());
    let widgetUrl = IFrameHelper.getUrl({
      baseUrl,
      websiteToken,
      isSecondWidget,
    });
    if (cwCookie) {
      widgetUrl = `${widgetUrl}&cw_conversation=${cwCookie}`;
    }
    iframe.src = widgetUrl;
    iframe.allowfullscreen = true;
    iframe.allow =
      'camera;microphone;fullscreen;display-capture;picture-in-picture;clipboard-write;';
    iframe.id = 'chatwoot_live_chat_widget';
    iframe.style.visibility = 'hidden';

    let holderClassName = `woot-widget-holder hoory-widget woot--hide woot-elements--${window.$hoory.position}`;
    if (window.$hoory.hideMessageBubble) {
      holderClassName += ` woot-widget--without-bubble`;
    }
    if (isFlatWidgetStyle(window.$hoory.widgetStyle)) {
      holderClassName += ` woot-widget-holder--flat`;
    }

    addClasses(widgetHolder, holderClassName);
    widgetHolder.appendChild(iframe);
    body.appendChild(widgetHolder);
    IFrameHelper.initPostMessageCommunication();
    IFrameHelper.initWindowSizeListener();
    IFrameHelper.preventDefaultScroll();

    /**
     * Trigger hoory error if the widget does not exist
     */
    fetch(iframe.src)
      .then(data => {
        if (data.status !== 200) {
          // eslint-disable-next-line no-console
          console.error('Hoory Error:', data.error);
          IFrameHelper.events.error({
            errorType: 'initialization',
            data: { msg: 'error loading inbox!', code: 1100 },
          });
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Hoory Error:', error);
        IFrameHelper.events.error({
          errorType: 'initialization',
          data: { msg: 'error loading inbox!', code: 1100 },
        });
      });
  },
  getAppFrame: () => document.getElementById('chatwoot_live_chat_widget'),
  getBubbleHolder: () => document.getElementsByClassName('woot--bubble-holder'),
  sendMessage: (key, value) => {
    const element = IFrameHelper.getAppFrame();
    /**
     * Prevent from error happening out if the iframe is not loaded
     */
    if (!element) return;
    element.contentWindow.postMessage(
      `chatwoot-widget:${JSON.stringify({ event: key, ...value })}`,
      '*'
    );
  },
  initPostMessageCommunication: () => {
    window.addEventListener('message', IFrameHelper.onWidgetMessage);
  },
  removeMessageCommunicationHandlers: () => {
    window.removeEventListener('message', IFrameHelper.onWidgetMessage);
  },
  onWidgetMessage: e => {
    if (
      typeof e.data !== 'string' ||
      e.data.indexOf('chatwoot-widget:') !== 0
    ) {
      return;
    }
    const message = JSON.parse(e.data.replace('chatwoot-widget:', ''));
    if (typeof IFrameHelper.events[message.event] === 'function') {
      IFrameHelper.events[message.event](message);
    }
  },
  initWindowSizeListener: () => {
    window.addEventListener('resize', () => IFrameHelper.toggleCloseButton());
  },
  preventDefaultScroll: () => {
    widgetHolder.addEventListener('wheel', event => {
      const deltaY = event.deltaY;
      const contentHeight = widgetHolder.scrollHeight;
      const visibleHeight = widgetHolder.offsetHeight;
      const scrollTop = widgetHolder.scrollTop;

      if (
        (scrollTop === 0 && deltaY < 0) ||
        (visibleHeight + scrollTop === contentHeight && deltaY > 0)
      ) {
        event.preventDefault();
      }
    });
  },

  setFrameHeightToFitContent: (extraHeight, isFixedHeight) => {
    const iframe = IFrameHelper.getAppFrame();
    const updatedIframeHeight = isFixedHeight ? `${extraHeight}px` : '100%';

    if (iframe)
      iframe.setAttribute('style', `height: ${updatedIframeHeight} !important`);
  },

  setupAudioListeners: () => {
    const { baseUrl = '' } = window.$hoory;
    getAlertAudio(baseUrl, { type: 'widget', alertTone: 'ding' }).then(() =>
      initOnEvents.forEach(event => {
        document.removeEventListener(
          event,
          IFrameHelper.setupAudioListeners,
          false
        );
      })
    );
  },

  events: {
    loaded: message => {
      /**
       * Set user from url if available for when we have a return url
       */
      setUserFromUrlIfAvailable();

      onLocationChangeListener();
      removeClasses(chatBubble, 'lazy-loading-hoory');

      updateAuthCookie(message.config.authToken);
      window.$hoory.hasLoaded = true;
      const campaignsSnoozedTill = Cookies.get('cw_snooze_campaigns_till');
      IFrameHelper.sendMessage('config-set', {
        locale: window.$hoory.locale,
        position: window.$hoory.position,
        hideMessageBubble: window.$hoory.hideMessageBubble,
        showPopoutButton: window.$hoory.showPopoutButton,
        widgetStyle: window.$hoory.widgetStyle,
        darkMode: window.$hoory.darkMode,
        forceManualLinkManagement: window.$hoory.forceManualLinkManagement,
        escapeUnreadView: window.$hoory.escapeUnreadView,
        allowWidgetMaximize: window.$hoory.allowWidgetMaximize,
        autoOpenUnreadConversation: window.$hoory.autoOpenUnreadConversation,
        campaignsSnoozedTill,
      });
      IFrameHelper.onLoad({
        widgetColor: message.config.channelConfig.widgetColor,
        avatarUrl: message.config.channelConfig.avatarUrl,
      });
      IFrameHelper.toggleCloseButton();

      if (window.$hoory.user) {
        IFrameHelper.sendMessage('set-user', window.$hoory.user);
      }

      window.playAudioAlert = () => {};

      initOnEvents.forEach(e => {
        document.addEventListener(e, IFrameHelper.setupAudioListeners, false);
      });

      if (!window.$hoory.resetTriggered) {
        dispatchWindowEvent({ eventName: CHATWOOT_READY });
      }
    },
    error: ({ errorType, data }) => {
      dispatchWindowEvent({ eventName: CHATWOOT_ERROR, data: data });

      if (errorType === SET_USER_ERROR) {
        Cookies.remove(getUserCookieName());
      }
    },
    onMessage({ data }) {
      dispatchWindowEvent({ eventName: CHATWOOT_ON_MESSAGE, data });
    },
    onConversationOpen({ data }) {
      dispatchWindowEvent({ eventName: CHATWOOT_ON_CONVERSATION_OPEN, data });
    },
    onConversationResolved({ data }) {
      dispatchWindowEvent({
        eventName: CHATWOOT_ON_CONVERSATION_RESOLVED,
        data,
      });
    },
    onLinkClick({ data }) {
      dispatchWindowEvent({ eventName: CHATWOOT_ON_LINK_CLICK, data });
    },
    openLink({ data }) {
      if (window.$hoory.forceManualLinkManagement) {
        dispatchWindowEvent({
          eventName: CHATWOOT_ON_LINK_CLICK,
          data: {
            href: data.url,
          },
        });
      } else {
        window.open(data.url, data.target || '_blank');
      }
    },

    setBubbleLabel(message) {
      setBubbleText(window.$hoory.launcherTitle || message.label);
    },

    setAuthCookie({ data: { widgetAuthToken } }) {
      updateAuthCookie(widgetAuthToken);
    },

    setCampaignReadOn() {
      updateCampaignReadStatus();
    },

    toggleBubble: state => {
      let bubbleState = {};
      if (
        (typeof state === 'string' && state?.startsWith('open')) ||
        (typeof state === 'object' && state.toggleValue === 'open')
      ) {
        bubbleState.toggleValue = true;
        if (state === 'open:last-conversation') {
          bubbleState.openLastConversation = true;
        } else if (state === 'open:new-conversation') {
          bubbleState.openNewConversation = true;
        } else if (typeof state === 'object' && state.conversation) {
          bubbleState.conversation = state.conversation;
        }
      } else if (state === 'close') {
        bubbleState.toggleValue = false;
      }

      onBubbleClick(bubbleState);
    },
    toggleExpand: state => {
      if (state.expandWidget) {
        widgetHolder.classList.add('woot--expanded');
      } else {
        widgetHolder.classList.remove('woot--expanded');
      }
    },
    executeScripts: async state => {
      // eslint-disable-next-line no-restricted-syntax
      for (const script of state.scripts) {
        // eslint-disable-next-line no-await-in-loop, no-eval
        if (script) await eval(script);
      }
    },

    popoutChatWindow: ({ baseUrl, websiteToken, locale }) => {
      const cwCookie = Cookies.get(getCookieName());
      window.$hoory.toggle('close');
      popoutChatWindow(baseUrl, websiteToken, locale, cwCookie);
    },

    closeWindow: () => {
      onBubbleClick({ toggleValue: false });
      removeUnreadClass();
    },

    showConversation(conversationId) {
      IFrameHelper.sendMessage('show-conversation', { conversationId });
    },

    onBubbleToggle: ({
      isOpen,
      isLoading,
      openLastConversation,
      openNewConversation,
      conversation,
    }) => {
      const eventData = {
        isOpen,
        isLoading,
        openLastConversation,
        openNewConversation,
        conversation,
      };
      dispatchWindowEvent({
        eventName: CHATWOOT_TOGGLE,
        data: eventData,
      });

      IFrameHelper.sendMessage('toggle-open', eventData);
      if (isLoading) {
        toggleClass(chatBubble, 'lazy-loading-hoory');
      } else {
        removeClasses(chatBubble, 'lazy-loading-hoory');
      }

      if (isOpen) {
        IFrameHelper.pushEvent('webwidget.triggered');
      }

      /**
       * If user closed a hidden hoory
       */
      if (
        window.$hoory.hideMessageBubble &&
        !isOpen &&
        !window.hoorySettings.hideMiniLauncher
      ) {
        addClasses(bubbleHolder, 'woot-mini');
      } else {
        removeClasses(bubbleHolder, 'woot-mini');
      }
    },
    onLocationChange: ({ referrerURL, referrerHost }) => {
      IFrameHelper.sendMessage('change-url', {
        referrerURL,
        referrerHost,
      });
    },
    onPageSizeChange: data => {
      IFrameHelper.sendMessage('window-size-change', data);
    },
    updateIframeHeight: message => {
      const { extraHeight = 0, isFixedHeight } = message;

      IFrameHelper.setFrameHeightToFitContent(extraHeight, isFixedHeight);
    },

    setUnreadMode: () => {
      addUnreadClass();
      onBubbleClick({ toggleValue: true });
    },

    resetUnreadMode: () => removeUnreadClass(),
    handleNotificationDot: event => {
      if (window.$hoory.hideMessageBubble) {
        return;
      }

      const bubbleElement = document.querySelector('.woot-widget-bubble');
      if (
        event.unreadMessageCount > 0 &&
        !bubbleElement.classList.contains('unread-notification')
      ) {
        addClasses(bubbleElement, 'unread-notification');
      } else if (event.unreadMessageCount === 0) {
        removeClasses(bubbleElement, 'unread-notification');
      }
    },

    closeChat: () => {
      onBubbleClick({ toggleValue: false });
    },

    playAudio: () => {
      window.playAudioAlert();
    },
  },
  pushEvent: eventName => {
    IFrameHelper.sendMessage('push-event', { eventName });
  },

  onLoad: ({ widgetColor, avatarUrl }) => {
    const iframe = IFrameHelper.getAppFrame();
    if (iframe) {
      iframe.style.visibility = '';
      iframe.setAttribute('id', `chatwoot_live_chat_widget`);
    }

    if (!window.$hoory.isLoading) {
      const createButtons = () => {
        createBubbleHolder(window.$hoory.hideMessageBubble);

        let className = 'woot-widget-bubble';

        if (isFlatWidgetStyle(window.$hoory.widgetStyle)) {
          className += ' woot-widget-bubble--flat';
        }

        if (isWidgetColorLighter(widgetColor)) {
          className += ' woot-widget-bubble-color--lighter';
        }

        const chatIcon = createBubbleIcon({
          className,
          target: chatBubble,
          avatarUrl,
        });

        chatIcon.style.background = widgetColor;
        chatIcon.querySelectorAll('.main-icon').forEach(icon => {
          icon.style.color = widgetColor;
        });

        bubbleHolder.appendChild(chatIcon);

        bubbleHolder.appendChild(createNotificationBubble());
      };

      if (IFrameHelper.getBubbleHolder().length) {
        const btnHolder = document.querySelector('.woot--bubble-holder');
        btnHolder.remove();
        chatBubble.setAttribute('data-loaded', 'false');
        chatBubble.innerHTML = '';

        setTimeout(() => {
          createButtons();
          onClickChatBubble();
        }, 10);
        setTimeout(() => setBubbleText(window.$hoory.launcherTitle), 20);
        return;
      }

      createButtons();
      onResizeListener();
      onClickChatBubble();
    } else {
      window.$hoory.isLoading = false;
    }
  },
  toggleCloseButton: () => {
    let isMobile = false;
    if (window.matchMedia('(max-width: 668px)').matches) {
      isMobile = true;
    }
    IFrameHelper.sendMessage('toggle-close-button', { isMobile });
  },
};
